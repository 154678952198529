var exports = {};
exports = nand2tetrisHdl;
nand2tetrisHdl.displayName = "nand2tetrisHdl";
nand2tetrisHdl.aliases = [];

function nand2tetrisHdl(Prism) {
  Prism.languages["nand2tetris-hdl"] = {
    comment: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
    keyword: /\b(?:CHIP|IN|OUT|PARTS|BUILTIN|CLOCKED)\b/,
    boolean: /\b(?:true|false)\b/,
    function: /\b[A-Za-z][A-Za-z0-9]*(?=\()/,
    number: /\b\d+\b/,
    operator: /=|\.\./,
    punctuation: /[{}[\];(),:]/
  };
}

export default exports;